import React from "react";
import IframeResizer from "iframe-resizer-react";

import Layout from "../components/layout";

import "../styles/global.scss";

const BookPage = ({ location }) => {
  IframeResizer({});
  return (
    <Layout title="Book">
      <IframeResizer
        checkOrigin={false}
        inPageLinks
        className="book__iframe"
        frameborder="0"
        src={
          location?.state?.url
            ? location?.state?.url
            : "https://direct-book.com/properties/theedwardhotelnydirect"
        }
        style={{ width: "1px", minWidth: "100%" }}
      />
    </Layout>
  );
};

export default BookPage;
